/* main.css */

  

  
  .slot-machine {
    background: white;
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
  }
  
  .spinners {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .spinner {
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    margin: 0 10px;
  }
  
  button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
  
  /* .message {
    margin-top: 20px;
    font-size: 20px;
    color: #333;
  } */
  
  /* main.css */

/* @keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); /* Assume each image is 100% of the container width 
  }
} */

/* .spinner {
  overflow: hidden; /* Assurez-vous que le contenu débordant est masqué
  white-space: nowrap; /* Empêche les images de se déplacer vers le bas 
} */

/* .slots {
  display: inline-block;
  animation: scroll 1s linear infinite; /* Ajustez la durée selon la vitesse souhaitée
} */
